<template>
    <layout>
        <div class="account-data mt-5">
            <div class="mb-10">
                <h1 class="heading-2">Account</h1>
            </div>
            <div class="">
                <router-link class="d-block my-5 text-14 dark-text" :to="{ name: 'account.data', params: {} }">Account information</router-link>
                <!-- <router-link class="d-block my-5 text-14 dark-text" :to="{ name: 'account.payment', params: {} }">Payment</router-link>
                <router-link class="d-block my-5 text-14 dark-text" :to="{ name: 'account.notification', params: {} }">Notifications</router-link> -->
                <div class="d-block my-5 text-14 dark-text" @click="logoutPopup = !logoutPopup">Sign out</div>
            </div>
            <v-dialog
                v-model="logoutPopup"
                transition="dialog-top-transition"
                max-width="300"
            >
                <template>
                    <v-card flat>
                        <v-card-text class="pa-3 pa-md-10 text-center">
                            <h2 class="text-28-60">Log out</h2>
                            <p class="mb-5 mt-3">Are you sure you want to sign out?</p>
                            <div class="d-flex justify-center">
                                <v-btn x-large class="secondary px-8 py-5 text-14 text-transform-none mr-4" @click="logoutPopup = !logoutPopup" depressed>Cancel</v-btn>
                                <div class="d-flex secondary">
                                    <v-btn x-large class="px-8 py-5 text-14 text-transform-none" depressed @click="logout">Sign out</v-btn>
                                </div>

                            </div>
                        </v-card-text>
                    </v-card>
                </template>
            </v-dialog>
        </div>
    </layout>
</template>

<script>
import Layout from '@/components/layouts/AccountLayout.vue'
export default {
    middleware: 'auth',
    components: {
        Layout,
    },
    data: () => ({
        logoutPopup: false
    }),
    mounted(){
        this.personal = this.$store.state.auth.user;
    },
    methods: {
        async logout () {
            await this.$store.dispatch('auth/logout')
            this.$router.push({ name: 'frontend.auth.login' })
        }
    }
}
</script>

<style lang="scss">
@import '@/assets/scss/_variables.scss';

@media all and (max-width: 600px){
    .v-dialog.v-dialog--active{
        margin: 0;
        box-shadow: none;
    }
}
</style>
